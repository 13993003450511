<template>
  <form class="asf-input-search" role="search" :name="name" @submit="handleSubmit">
    <AsfButton
      v-e2e="'search-submit-button'"
      class="asf-input-search_submit"
      type="submit"
      :title="`${$t('search.word')}`"
      :aria-label="`${$t('search.word').toString()}`"
    >
      <AsfIcon name="search" />
    </AsfButton>
    <AsfInput
      id="query"
      v-bind="inputAttributes"
      v-model:value="searchPhrase"
      v-e2e="'search-field'"
      :label="$t('search.word')"
      :show-label="false"
      :placeholder="$t('search.word')"
      :maxlength="50"
      class="asf-input-search__field"
    />

    <AsfButton
      v-e2e="'search-reset-button'"
      :class="['asf-input-search__clear', { 'asf-input-search__clear--visible': searchPhrase }]"
      type="reset"
      :title="`${$t('forms.clearInput')}`"
      @click="clearInput"
      @keydown.enter="clearInput"
    >
      <AsfIcon name="clear" size="5" />
    </AsfButton>
  </form>
</template>
<script lang="ts" setup>
import type { AsfInputSearchProps } from '@ui/types'

const props = defineProps<AsfInputSearchProps>()
const emit = defineEmits(['search:submit', 'search:change'])
const searchPhrase = ref(props.value || '')
const clearInput = () => (searchPhrase.value = '')

const handleSubmit = (event: Event) => {
  event.preventDefault()
  if (searchPhrase.value) {
    emit('search:submit', searchPhrase.value)
    clearInput()
  }
}

watch(searchPhrase, (phrase = '') => {
  emit('search:change', phrase)
})

const inputAttributes = computed(() => ({
  autocapitalize: 'off',
  autocomplete: 'off',
  autocorrect: 'false',
  spellcheck: 'false',
  ...props.inputA11y
}))
</script>
<style lang="postcss">
@import '@components/molecules/InputSearch/InputSearch.css';
</style>
