<template>
  <component
    :is="tag || 'div'"
    ref="sliding-panel-ref"
    class="asf-sidebar__sliding-panel"
    :style="{ transform: `translateX(-${currentState.level * 100}%)` }"
  >
    <slot :current-state="currentState" :close-current-panel="closeCurrentPanel" />
  </component>
</template>
<script lang="ts">
import type { AsfSidebarSlidingItemProps, AsfSidebarSlidingPanelProps, AsfSidebarSlidingPanelState } from '@ui/types'
import { CloseCurrentPanelKey, OpenPanelKey, PanelStateKey } from '../Sidebar.utils'

export default defineComponent({
  name: 'AsfSidebarSlidingPanel',
  props: {
    initialPanels: { type: Array as PropType<AsfSidebarSlidingPanelProps['initialPanels']>, default: () => [] },
    tag: { type: String as PropType<AsfSidebarSlidingPanelProps['tag']>, default: 'div' },
    /** Determines which element to focus onMount: true => firstChild, false = lastChild */
    isFocusFirst: { type: Boolean as PropType<AsfSidebarSlidingPanelProps['isFocusFirst']>, default: true }
  },
  setup(props: AsfSidebarSlidingPanelProps, { emit }) {
    const currentState = reactive<AsfSidebarSlidingPanelState>({
      level: 0,
      panels: [],
      isFocusFirst: props.isFocusFirst
    })

    const openPanel = (payload: AsfSidebarSlidingItemProps) => {
      currentState.level += 1
      currentState.isFocusFirst = true
      currentState.panels.push(payload)

      emit('change:panel', currentState)
    }

    const closeCurrentPanel = () => {
      emit('close:panel', currentState.panels[currentState.level - 1]?.id)

      if (currentState.level === 0) return
      currentState.panels.pop()
      currentState.level -= 1

      emit('change:panel', currentState)
    }

    onBeforeMount(() => {
      if (props.initialPanels) {
        props.initialPanels.forEach((p) => {
          currentState.level += 1
          currentState.panels.push(p)
        })
        emit('change:panel', currentState)
      }
    })

    onMounted(() => {
      EventBus.on('sidebar:back', closeCurrentPanel)
    })

    onBeforeUnmount(() => {
      EventBus.off('sidebar:back', closeCurrentPanel)
    })

    provide(PanelStateKey, currentState)
    provide(OpenPanelKey, openPanel)
    provide(CloseCurrentPanelKey, closeCurrentPanel)

    return { currentState, closeCurrentPanel }
  }
})
</script>
