<template>
  <component :is="link ? AsfLink : AsfButton" class="asf-menu-item" v-bind="attributes" v-e2e="`${label}-menu-item`">
    <slot name="content-icon" />
    <span class="asf-menu-item__inner">
      <span class="asf-menu-item__label">{{ label }}</span>
      <span class="asf-menu-item__content">
        <slot name="content" />
      </span>
    </span>

    <AsfIcon v-if="showIcon && iconName" :name="iconName" size="5" class="asf-menu-item__icon" />
  </component>
</template>
<script lang="ts" setup>
import type { AsfMenuItemProps } from '@ui/types'
import { AsfButton, AsfLink } from '#components'

const props = withDefaults(defineProps<AsfMenuItemProps>(), {
  label: '',
  iconName: 'chevron-right',
  showIcon: true,
  prevent: false
})

const attrs = useAttrs()

const attributes = computed(() => {
  const attributesProps = {
    ...attrs
  }

  if (props.link) {
    attributesProps.link = props.link
    attributesProps.prevent = props.prevent
  }

  return attributesProps
})
</script>
<style lang="postcss">
@import '@components/molecules/MenuItem/MenuItem.css';
</style>
